export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"msapplication-TileColor","content":"#193060"},{"name":"msapplication-config","content":"images/browserconfig.xml"},{"name":"theme-color","content":"#193060"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/images/favicon_transkribus.svg"},{"rel":"icon","type":"image/svg","sizes":"32x32","href":"/images/favicon_transkribus.svg"}],"style":[],"script":[{"src":"https://app.usercentrics.eu/browser-ui/latest/loader.js","async":true,"id":"usercentrics-cmp","data-settings-id":"eHTMKkPzNccSOA"}],"noscript":[],"title":"Transkribus"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false