export default defineNuxtPlugin(nuxtApp => {
  // TODO check if we can use the feature flag in posthog
  const config = useRuntimeConfig()

  const isAlpha = config.public.name === 'alpha'
  const isBeta = config.public.name === 'beta'
  const isApp = config.public.name === 'app'

  const features = {
    tableAdvancedSetting: isAlpha || isBeta,
    scrollable: isAlpha,
    learn: isAlpha || isBeta,
    qc: isBeta,
    trainingRuns: isAlpha,
    comments: isAlpha,
    pageMetadata: isAlpha,
    posthogTesting: isAlpha,
    showDocStatus: isAlpha || isBeta,
    dan: isAlpha || isBeta,
    s3ModelThumbnail: isAlpha || isBeta,
    orchestratorTest: isAlpha,
    surveys: isAlpha || isBeta || isApp,
    notifications: isAlpha,
    favoriteCollections: isAlpha || isBeta || isApp,
    userSettings: isAlpha || isBeta || isApp,
    lazyLoading: isAlpha || isBeta || isApp,
    uppyTAI: isAlpha || isBeta,
    inviteLinkSitesOrga: isAlpha || isBeta || isApp,
    docAndPageLabels: isAlpha || isBeta,
    customSitesTheme: isAlpha || isBeta || isApp,
    modelLinkName: isAlpha || isBeta || isApp,
    usageInCollectionSettings: isAlpha || isBeta || isApp,
    superModelChip: isAlpha || isBeta || isApp,
    newSitesFilter: isAlpha || isBeta || isApp,
    sitesSearchResultPagination: isAlpha || isBeta || isApp,
    importsAndDownloadColSettings: isAlpha || isBeta || isApp,
    activityJob: isAlpha || isBeta || isApp,
    onboardingTour: isAlpha || isBeta || isApp,
    onboardingTourStartButton: isAlpha,
    publishModel: isAlpha,
  }
  return {
    provide: {
      features,
    },
  }
})
