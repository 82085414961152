import { useHead } from '@vueuse/head'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  const isAlpha = config.public.name === 'alpha'
  const isBeta = config.public.name === 'beta'
  const isApp = config.public.name === 'app'

  // Define your globally available functions
  const setPageTitle = (title: string) => {
    // todo remove feature check
    if (isAlpha || isBeta || isApp) {
      let docTitle = 'Transkribus'
      if (title) {
        docTitle = `${title} - ${docTitle}`
      }
      useHead({
        title: docTitle,
      })
    }
  }
  // Add the functions to the Nuxt app instance
  nuxtApp.provide('setPageTitle', setPageTitle)
})
