import { fetchEventSource } from '@microsoft/fetch-event-source'
import { useKeycloak } from '~/stores/keycloak'

interface Count {
  count: number
}

export default defineNuxtPlugin(nuxtApp => {
  let isConnected = false
  let controller: AbortController | null = null
  const callbacks: ((count: number) => void)[] = []
  const url = 'http://localhost:8080/notifications/onEvent'

  const disconnect = () => {
    if (controller) {
      controller.abort()
      controller = null
      isConnected = false
    }
  }

  const connect = async () => {
    if (controller) {
      disconnect()
    }

    controller = new AbortController()
    isConnected = true

    return

    try {
      const keycloakStore = useKeycloak()
      const token = keycloakStore.keycloak?.token
      await fetchEventSource(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
        },
        signal: controller.signal,
        onmessage(event) {
          try {
            const data: Count = JSON.parse(event.data)
            callbacks.forEach(callback => callback(data.count))
          } catch (error) {
            console.error('Error parsing SSE data:', error)
          }
        },
        async onopen(response) {
          if (!response.ok) {
            disconnect()
          }
        },
        onerror(error) {
          console.error('SSE connection error:', error)
          disconnect()
        },
      })
    } catch (error) {
      console.error('Failed to establish SSE connection:', error)
      disconnect()
    }
  }

  const on = (eventName: string, callback: (count: number) => void) => {
    if (eventName === 'newNotificationCount') {
      callbacks.push(callback)
    }
  }

  const off = (eventName: string, callback: (count: number) => void) => {
    if (eventName === 'newNotificationCount') {
      callbacks.splice(callbacks.indexOf(callback), 1)
    }
  }

  nuxtApp.provide('notifications', {
    connect,
    on,
    off,
  })
})
