import { default as index39rnUmlpHdMeta } from "/opt/apps/transkribus_app/pages/activity/advanced/index.vue?macro=true";
import { default as indexX6tT3XdoaSMeta } from "/opt/apps/transkribus_app/pages/activity/all/index.vue?macro=true";
import { default as indexsFHjiXYDM4Meta } from "/opt/apps/transkribus_app/pages/activity/orchestrator/index.vue?macro=true";
import { default as indexeunKJOcLxCMeta } from "/opt/apps/transkribus_app/pages/activity/recognition/index.vue?macro=true";
import { default as indexniniQZhddDMeta } from "/opt/apps/transkribus_app/pages/activity/up-download/index.vue?macro=true";
import { default as buildEseffiSZ8DMeta } from "/opt/apps/transkribus_app/pages/build.vue?macro=true";
import { default as changelogVhqMrXf4T1Meta } from "/opt/apps/transkribus_app/pages/changelog.vue?macro=true";
import { default as indexavDuVCkAnAMeta } from "/opt/apps/transkribus_app/pages/collection-settings/index.vue?macro=true";
import { default as indexwZoJAlLJIrMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/detail/[pageid]/index.vue?macro=true";
import { default as index0tij4HpJdbMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/[pageid]/index.vue?macro=true";
import { default as indexRZhM61kkHkMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/index.vue?macro=true";
import { default as indexRbmkkavlyhMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/index.vue?macro=true";
import { default as indexHES2Xt0ECbMeta } from "/opt/apps/transkribus_app/pages/collection/[collectionid]/index.vue?macro=true";
import { default as indexlHQl0jdKTjMeta } from "/opt/apps/transkribus_app/pages/collection/index.vue?macro=true";
import { default as indexN46pAdXDa6Meta } from "/opt/apps/transkribus_app/pages/data-lab/index.vue?macro=true";
import { default as indexQjhyC2ZwMtMeta } from "/opt/apps/transkribus_app/pages/data-lab/train/index.vue?macro=true";
import { default as guideMXaVefcVTDMeta } from "/opt/apps/transkribus_app/pages/guide.vue?macro=true";
import { default as indexvD7d4YvqU9Meta } from "/opt/apps/transkribus_app/pages/index.vue?macro=true";
import { default as indexUMKZ3DCoEnMeta } from "/opt/apps/transkribus_app/pages/invitations/[inviteid]/index.vue?macro=true";
import { default as indexlkRNpR0fn1Meta } from "/opt/apps/transkribus_app/pages/invitations/index.vue?macro=true";
import { default as indexnl2DjtE0RvMeta } from "/opt/apps/transkribus_app/pages/jobs/index.vue?macro=true";
import { default as index3pafii4cj3Meta } from "/opt/apps/transkribus_app/pages/learn/index.vue?macro=true";
import { default as loginWc5rK6qQ1vMeta } from "/opt/apps/transkribus_app/pages/login.vue?macro=true";
import { default as indexwK752oTjBtMeta } from "/opt/apps/transkribus_app/pages/models/[type]/[modelId]/index.vue?macro=true";
import { default as indextq7J8ERWonMeta } from "/opt/apps/transkribus_app/pages/models/[type]/[modelId]/modify/index.vue?macro=true";
import { default as indexHykYlM1vzzMeta } from "/opt/apps/transkribus_app/pages/models/[type]/[modelId]/train-data/index.vue?macro=true";
import { default as indexV63Sgms0yUMeta } from "/opt/apps/transkribus_app/pages/models/index.vue?macro=true";
import { default as indexc64QmF1uFTMeta } from "/opt/apps/transkribus_app/pages/models/public/[modelId]/index.vue?macro=true";
import { default as indexiSl6x5gxViMeta } from "/opt/apps/transkribus_app/pages/models/public/index.vue?macro=true";
import { default as indexnTRrIwrzasMeta } from "/opt/apps/transkribus_app/pages/notifications/index.vue?macro=true";
import { default as indexpTeEzg722IMeta } from "/opt/apps/transkribus_app/pages/public-models/index.vue?macro=true";
import { default as indexHhBbHSHOo2Meta } from "/opt/apps/transkribus_app/pages/quality-control/index.vue?macro=true";
import { default as index4CaPPrF8bsMeta } from "/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/index.vue?macro=true";
import { default as index57Ng6nc8UYMeta } from "/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/page/[pageid]/index.vue?macro=true";
import { default as indexml7GqRC7KLMeta } from "/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/results/index.vue?macro=true";
import { default as indexgfPq2jENvMMeta } from "/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/index.vue?macro=true";
import { default as indexKBeml5z1ATMeta } from "/opt/apps/transkribus_app/pages/search/index.vue?macro=true";
import { default as indexpVdRqhJaYFMeta } from "/opt/apps/transkribus_app/pages/share/[hash]/index.vue?macro=true";
import { default as indexRc05IlraZTMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/about/index.vue?macro=true";
import { default as indexp371v0aWPEMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/browse/index.vue?macro=true";
import { default as index3d4k6cTdLXMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/content/[contentName]/index.vue?macro=true";
import { default as indexUE9bxe1FVtMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/[pageid]/index.vue?macro=true";
import { default as indexa8JBJIFiX2Meta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/index.vue?macro=true";
import { default as indexKdPkQEzfIqMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/index.vue?macro=true";
import { default as index12cQp5VGDhMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/imprint/index.vue?macro=true";
import { default as indexxADbEotZbTMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/index.vue?macro=true";
import { default as indexZtTG5lT1nlMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/search/index.vue?macro=true";
import { default as indexfUZo03EhcEMeta } from "/opt/apps/transkribus_app/pages/sites/[url]/tags/index.vue?macro=true";
import { default as indexgKTCqU83lTMeta } from "/opt/apps/transkribus_app/pages/sites/index.vue?macro=true";
import { default as indexYxHtTetahJMeta } from "/opt/apps/transkribus_app/pages/upload/index.vue?macro=true";
import { default as index2Ad79aD9acMeta } from "/opt/apps/transkribus_app/pages/usage/index.vue?macro=true";
import { default as indexfWSuG00AO7Meta } from "/opt/apps/transkribus_app/pages/usage/manage-organisation/[organisationid]/index.vue?macro=true";
import { default as indexLQ7z3ErTiiMeta } from "/opt/apps/transkribus_app/pages/user-settings/index.vue?macro=true";
import { default as index8v9ec8gGcaMeta } from "/opt/apps/transkribus_app/pages/version/index.vue?macro=true";
import { default as indexhRZXvPcETFMeta } from "/opt/apps/transkribus_app/pages/welcome/index.vue?macro=true";
import { default as component_45stubKzQrTTlmreMeta } from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubKzQrTTlmre } from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: index39rnUmlpHdMeta?.name ?? "activity-advanced",
    path: index39rnUmlpHdMeta?.path ?? "/activity/advanced",
    meta: index39rnUmlpHdMeta || {},
    alias: index39rnUmlpHdMeta?.alias || [],
    redirect: index39rnUmlpHdMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/activity/advanced/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6tT3XdoaSMeta?.name ?? "activity-all",
    path: indexX6tT3XdoaSMeta?.path ?? "/activity/all",
    meta: indexX6tT3XdoaSMeta || {},
    alias: indexX6tT3XdoaSMeta?.alias || [],
    redirect: indexX6tT3XdoaSMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/activity/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexsFHjiXYDM4Meta?.name ?? "activity-orchestrator",
    path: indexsFHjiXYDM4Meta?.path ?? "/activity/orchestrator",
    meta: indexsFHjiXYDM4Meta || {},
    alias: indexsFHjiXYDM4Meta?.alias || [],
    redirect: indexsFHjiXYDM4Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/activity/orchestrator/index.vue").then(m => m.default || m)
  },
  {
    name: indexeunKJOcLxCMeta?.name ?? "activity-recognition",
    path: indexeunKJOcLxCMeta?.path ?? "/activity/recognition",
    meta: indexeunKJOcLxCMeta || {},
    alias: indexeunKJOcLxCMeta?.alias || [],
    redirect: indexeunKJOcLxCMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/activity/recognition/index.vue").then(m => m.default || m)
  },
  {
    name: indexniniQZhddDMeta?.name ?? "activity-up-download",
    path: indexniniQZhddDMeta?.path ?? "/activity/up-download",
    meta: indexniniQZhddDMeta || {},
    alias: indexniniQZhddDMeta?.alias || [],
    redirect: indexniniQZhddDMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/activity/up-download/index.vue").then(m => m.default || m)
  },
  {
    name: buildEseffiSZ8DMeta?.name ?? "build",
    path: buildEseffiSZ8DMeta?.path ?? "/build",
    meta: buildEseffiSZ8DMeta || {},
    alias: buildEseffiSZ8DMeta?.alias || [],
    redirect: buildEseffiSZ8DMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/build.vue").then(m => m.default || m)
  },
  {
    name: changelogVhqMrXf4T1Meta?.name ?? "changelog",
    path: changelogVhqMrXf4T1Meta?.path ?? "/changelog",
    meta: changelogVhqMrXf4T1Meta || {},
    alias: changelogVhqMrXf4T1Meta?.alias || [],
    redirect: changelogVhqMrXf4T1Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: indexavDuVCkAnAMeta?.name ?? "collection-settings",
    path: indexavDuVCkAnAMeta?.path ?? "/collection-settings",
    meta: indexavDuVCkAnAMeta || {},
    alias: indexavDuVCkAnAMeta?.alias || [],
    redirect: indexavDuVCkAnAMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexwZoJAlLJIrMeta?.name ?? "collection-collectionid-doc-docid-detail-pageid",
    path: indexwZoJAlLJIrMeta?.path ?? "/collection/:collectionid()/doc/:docid()/detail/:pageid()",
    meta: indexwZoJAlLJIrMeta || {},
    alias: indexwZoJAlLJIrMeta?.alias || [],
    redirect: indexwZoJAlLJIrMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/detail/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: index0tij4HpJdbMeta?.name ?? "collection-collectionid-doc-docid-edit-pageid",
    path: index0tij4HpJdbMeta?.path ?? "/collection/:collectionid()/doc/:docid()/edit/:pageid()",
    meta: index0tij4HpJdbMeta || {},
    alias: index0tij4HpJdbMeta?.alias || [],
    redirect: index0tij4HpJdbMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRZhM61kkHkMeta?.name ?? "collection-collectionid-doc-docid-edit",
    path: indexRZhM61kkHkMeta?.path ?? "/collection/:collectionid()/doc/:docid()/edit",
    meta: indexRZhM61kkHkMeta || {},
    alias: indexRZhM61kkHkMeta?.alias || [],
    redirect: indexRZhM61kkHkMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexRbmkkavlyhMeta?.name ?? "collection-collectionid-doc-docid",
    path: indexRbmkkavlyhMeta?.path ?? "/collection/:collectionid()/doc/:docid()",
    meta: indexRbmkkavlyhMeta || {},
    alias: indexRbmkkavlyhMeta?.alias || [],
    redirect: indexRbmkkavlyhMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/doc/[docid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHES2Xt0ECbMeta?.name ?? "collection-collectionid",
    path: indexHES2Xt0ECbMeta?.path ?? "/collection/:collectionid()",
    meta: indexHES2Xt0ECbMeta || {},
    alias: indexHES2Xt0ECbMeta?.alias || [],
    redirect: indexHES2Xt0ECbMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/[collectionid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlHQl0jdKTjMeta?.name ?? "collection",
    path: indexlHQl0jdKTjMeta?.path ?? "/collection",
    meta: indexlHQl0jdKTjMeta || {},
    alias: indexlHQl0jdKTjMeta?.alias || [],
    redirect: indexlHQl0jdKTjMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/collection/index.vue").then(m => m.default || m)
  },
  {
    name: indexN46pAdXDa6Meta?.name ?? "data-lab",
    path: indexN46pAdXDa6Meta?.path ?? "/data-lab",
    meta: indexN46pAdXDa6Meta || {},
    alias: indexN46pAdXDa6Meta?.alias || [],
    redirect: indexN46pAdXDa6Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/data-lab/index.vue").then(m => m.default || m)
  },
  {
    name: indexQjhyC2ZwMtMeta?.name ?? "data-lab-train",
    path: indexQjhyC2ZwMtMeta?.path ?? "/data-lab/train",
    meta: indexQjhyC2ZwMtMeta || {},
    alias: indexQjhyC2ZwMtMeta?.alias || [],
    redirect: indexQjhyC2ZwMtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/data-lab/train/index.vue").then(m => m.default || m)
  },
  {
    name: guideMXaVefcVTDMeta?.name ?? "guide",
    path: guideMXaVefcVTDMeta?.path ?? "/guide",
    meta: guideMXaVefcVTDMeta || {},
    alias: guideMXaVefcVTDMeta?.alias || [],
    redirect: guideMXaVefcVTDMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexvD7d4YvqU9Meta?.name ?? "index",
    path: indexvD7d4YvqU9Meta?.path ?? "/",
    meta: indexvD7d4YvqU9Meta || {},
    alias: indexvD7d4YvqU9Meta?.alias || [],
    redirect: indexvD7d4YvqU9Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUMKZ3DCoEnMeta?.name ?? "invitations-inviteid",
    path: indexUMKZ3DCoEnMeta?.path ?? "/invitations/:inviteid()",
    meta: indexUMKZ3DCoEnMeta || {},
    alias: indexUMKZ3DCoEnMeta?.alias || [],
    redirect: indexUMKZ3DCoEnMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/invitations/[inviteid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlkRNpR0fn1Meta?.name ?? "invitations",
    path: indexlkRNpR0fn1Meta?.path ?? "/invitations",
    meta: indexlkRNpR0fn1Meta || {},
    alias: indexlkRNpR0fn1Meta?.alias || [],
    redirect: indexlkRNpR0fn1Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/invitations/index.vue").then(m => m.default || m)
  },
  {
    name: indexnl2DjtE0RvMeta?.name ?? "jobs",
    path: indexnl2DjtE0RvMeta?.path ?? "/jobs",
    meta: indexnl2DjtE0RvMeta || {},
    alias: indexnl2DjtE0RvMeta?.alias || [],
    redirect: indexnl2DjtE0RvMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index3pafii4cj3Meta?.name ?? "learn",
    path: index3pafii4cj3Meta?.path ?? "/learn",
    meta: index3pafii4cj3Meta || {},
    alias: index3pafii4cj3Meta?.alias || [],
    redirect: index3pafii4cj3Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/learn/index.vue").then(m => m.default || m)
  },
  {
    name: loginWc5rK6qQ1vMeta?.name ?? "login",
    path: loginWc5rK6qQ1vMeta?.path ?? "/login",
    meta: loginWc5rK6qQ1vMeta || {},
    alias: loginWc5rK6qQ1vMeta?.alias || [],
    redirect: loginWc5rK6qQ1vMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexwK752oTjBtMeta?.name ?? "models-type-modelId",
    path: indexwK752oTjBtMeta?.path ?? "/models/:type()/:modelId()",
    meta: indexwK752oTjBtMeta || {},
    alias: indexwK752oTjBtMeta?.alias || [],
    redirect: indexwK752oTjBtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/[type]/[modelId]/index.vue").then(m => m.default || m)
  },
  {
    name: indextq7J8ERWonMeta?.name ?? "models-type-modelId-modify",
    path: indextq7J8ERWonMeta?.path ?? "/models/:type()/:modelId()/modify",
    meta: indextq7J8ERWonMeta || {},
    alias: indextq7J8ERWonMeta?.alias || [],
    redirect: indextq7J8ERWonMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/[type]/[modelId]/modify/index.vue").then(m => m.default || m)
  },
  {
    name: indexHykYlM1vzzMeta?.name ?? "models-type-modelId-train-data",
    path: indexHykYlM1vzzMeta?.path ?? "/models/:type()/:modelId()/train-data",
    meta: indexHykYlM1vzzMeta || {},
    alias: indexHykYlM1vzzMeta?.alias || [],
    redirect: indexHykYlM1vzzMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/[type]/[modelId]/train-data/index.vue").then(m => m.default || m)
  },
  {
    name: indexV63Sgms0yUMeta?.name ?? "models",
    path: indexV63Sgms0yUMeta?.path ?? "/models",
    meta: indexV63Sgms0yUMeta || {},
    alias: indexV63Sgms0yUMeta?.alias || [],
    redirect: indexV63Sgms0yUMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexc64QmF1uFTMeta?.name ?? "models-public-modelId",
    path: indexc64QmF1uFTMeta?.path ?? "/models/public/:modelId()",
    meta: indexc64QmF1uFTMeta || {},
    alias: indexc64QmF1uFTMeta?.alias || [],
    redirect: indexc64QmF1uFTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/public/[modelId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSl6x5gxViMeta?.name ?? "models-public",
    path: indexiSl6x5gxViMeta?.path ?? "/models/public",
    meta: indexiSl6x5gxViMeta || {},
    alias: indexiSl6x5gxViMeta?.alias || [],
    redirect: indexiSl6x5gxViMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/models/public/index.vue").then(m => m.default || m)
  },
  {
    name: indexnTRrIwrzasMeta?.name ?? "notifications",
    path: indexnTRrIwrzasMeta?.path ?? "/notifications",
    meta: indexnTRrIwrzasMeta || {},
    alias: indexnTRrIwrzasMeta?.alias || [],
    redirect: indexnTRrIwrzasMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTeEzg722IMeta?.name ?? "public-models",
    path: indexpTeEzg722IMeta?.path ?? "/public-models",
    meta: indexpTeEzg722IMeta || {},
    alias: indexpTeEzg722IMeta?.alias || [],
    redirect: indexpTeEzg722IMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/public-models/index.vue").then(m => m.default || m)
  },
  {
    name: indexHhBbHSHOo2Meta?.name ?? "quality-control",
    path: indexHhBbHSHOo2Meta?.path ?? "/quality-control",
    meta: indexHhBbHSHOo2Meta || {},
    alias: indexHhBbHSHOo2Meta?.alias || [],
    redirect: indexHhBbHSHOo2Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/index.vue").then(m => m.default || m)
  },
  {
    name: index4CaPPrF8bsMeta?.name ?? "quality-control-sample-sampleid-evaluation-evaluationid",
    path: index4CaPPrF8bsMeta?.path ?? "/quality-control/sample/:sampleid()/evaluation/:evaluationid()",
    meta: index4CaPPrF8bsMeta || {},
    alias: index4CaPPrF8bsMeta?.alias || [],
    redirect: index4CaPPrF8bsMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/index.vue").then(m => m.default || m)
  },
  {
    name: index57Ng6nc8UYMeta?.name ?? "quality-control-sample-sampleid-evaluation-evaluationid-page-pageid",
    path: index57Ng6nc8UYMeta?.path ?? "/quality-control/sample/:sampleid()/evaluation/:evaluationid()/page/:pageid()",
    meta: index57Ng6nc8UYMeta || {},
    alias: index57Ng6nc8UYMeta?.alias || [],
    redirect: index57Ng6nc8UYMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/page/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexml7GqRC7KLMeta?.name ?? "quality-control-sample-sampleid-evaluation-evaluationid-results",
    path: indexml7GqRC7KLMeta?.path ?? "/quality-control/sample/:sampleid()/evaluation/:evaluationid()/results",
    meta: indexml7GqRC7KLMeta || {},
    alias: indexml7GqRC7KLMeta?.alias || [],
    redirect: indexml7GqRC7KLMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/evaluation/[evaluationid]/results/index.vue").then(m => m.default || m)
  },
  {
    name: indexgfPq2jENvMMeta?.name ?? "quality-control-sample-sampleid",
    path: indexgfPq2jENvMMeta?.path ?? "/quality-control/sample/:sampleid()",
    meta: indexgfPq2jENvMMeta || {},
    alias: indexgfPq2jENvMMeta?.alias || [],
    redirect: indexgfPq2jENvMMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/quality-control/sample/[sampleid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKBeml5z1ATMeta?.name ?? "search",
    path: indexKBeml5z1ATMeta?.path ?? "/search",
    meta: indexKBeml5z1ATMeta || {},
    alias: indexKBeml5z1ATMeta?.alias || [],
    redirect: indexKBeml5z1ATMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexpVdRqhJaYFMeta?.name ?? "share-hash",
    path: indexpVdRqhJaYFMeta?.path ?? "/share/:hash()",
    meta: indexpVdRqhJaYFMeta || {},
    alias: indexpVdRqhJaYFMeta?.alias || [],
    redirect: indexpVdRqhJaYFMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/share/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRc05IlraZTMeta?.name ?? "sites-url-about",
    path: indexRc05IlraZTMeta?.path ?? "/sites/:url()/about",
    meta: indexRc05IlraZTMeta || {},
    alias: indexRc05IlraZTMeta?.alias || [],
    redirect: indexRc05IlraZTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexp371v0aWPEMeta?.name ?? "sites-url-browse",
    path: indexp371v0aWPEMeta?.path ?? "/sites/:url()/browse",
    meta: indexp371v0aWPEMeta || {},
    alias: indexp371v0aWPEMeta?.alias || [],
    redirect: indexp371v0aWPEMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/browse/index.vue").then(m => m.default || m)
  },
  {
    name: index3d4k6cTdLXMeta?.name ?? "sites-url-content-contentName",
    path: index3d4k6cTdLXMeta?.path ?? "/sites/:url()/content/:contentName()",
    meta: index3d4k6cTdLXMeta || {},
    alias: index3d4k6cTdLXMeta?.alias || [],
    redirect: index3d4k6cTdLXMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/content/[contentName]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUE9bxe1FVtMeta?.name ?? "sites-url-doc-docid-detail-pageid",
    path: indexUE9bxe1FVtMeta?.path ?? "/sites/:url()/doc/:docid()/detail/:pageid()",
    meta: indexUE9bxe1FVtMeta || {},
    alias: indexUE9bxe1FVtMeta?.alias || [],
    redirect: indexUE9bxe1FVtMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/[pageid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8JBJIFiX2Meta?.name ?? "sites-url-doc-docid-detail",
    path: indexa8JBJIFiX2Meta?.path ?? "/sites/:url()/doc/:docid()/detail",
    meta: indexa8JBJIFiX2Meta || {},
    alias: indexa8JBJIFiX2Meta?.alias || [],
    redirect: indexa8JBJIFiX2Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdPkQEzfIqMeta?.name ?? "sites-url-doc-docid",
    path: indexKdPkQEzfIqMeta?.path ?? "/sites/:url()/doc/:docid()",
    meta: indexKdPkQEzfIqMeta || {},
    alias: indexKdPkQEzfIqMeta?.alias || [],
    redirect: indexKdPkQEzfIqMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/doc/[docid]/index.vue").then(m => m.default || m)
  },
  {
    name: index12cQp5VGDhMeta?.name ?? "sites-url-imprint",
    path: index12cQp5VGDhMeta?.path ?? "/sites/:url()/imprint",
    meta: index12cQp5VGDhMeta || {},
    alias: index12cQp5VGDhMeta?.alias || [],
    redirect: index12cQp5VGDhMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/imprint/index.vue").then(m => m.default || m)
  },
  {
    name: indexxADbEotZbTMeta?.name ?? "sites-url",
    path: indexxADbEotZbTMeta?.path ?? "/sites/:url()",
    meta: indexxADbEotZbTMeta || {},
    alias: indexxADbEotZbTMeta?.alias || [],
    redirect: indexxADbEotZbTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZtTG5lT1nlMeta?.name ?? "sites-url-search",
    path: indexZtTG5lT1nlMeta?.path ?? "/sites/:url()/search",
    meta: indexZtTG5lT1nlMeta || {},
    alias: indexZtTG5lT1nlMeta?.alias || [],
    redirect: indexZtTG5lT1nlMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUZo03EhcEMeta?.name ?? "sites-url-tags",
    path: indexfUZo03EhcEMeta?.path ?? "/sites/:url()/tags",
    meta: indexfUZo03EhcEMeta || {},
    alias: indexfUZo03EhcEMeta?.alias || [],
    redirect: indexfUZo03EhcEMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/[url]/tags/index.vue").then(m => m.default || m)
  },
  {
    name: indexgKTCqU83lTMeta?.name ?? "sites",
    path: indexgKTCqU83lTMeta?.path ?? "/sites",
    meta: indexgKTCqU83lTMeta || {},
    alias: indexgKTCqU83lTMeta?.alias || [],
    redirect: indexgKTCqU83lTMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/sites/index.vue").then(m => m.default || m)
  },
  {
    name: indexYxHtTetahJMeta?.name ?? "upload",
    path: indexYxHtTetahJMeta?.path ?? "/upload",
    meta: indexYxHtTetahJMeta || {},
    alias: indexYxHtTetahJMeta?.alias || [],
    redirect: indexYxHtTetahJMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: index2Ad79aD9acMeta?.name ?? "usage",
    path: index2Ad79aD9acMeta?.path ?? "/usage",
    meta: index2Ad79aD9acMeta || {},
    alias: index2Ad79aD9acMeta?.alias || [],
    redirect: index2Ad79aD9acMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/usage/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWSuG00AO7Meta?.name ?? "usage-manage-organisation-organisationid",
    path: indexfWSuG00AO7Meta?.path ?? "/usage/manage-organisation/:organisationid()",
    meta: indexfWSuG00AO7Meta || {},
    alias: indexfWSuG00AO7Meta?.alias || [],
    redirect: indexfWSuG00AO7Meta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/usage/manage-organisation/[organisationid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLQ7z3ErTiiMeta?.name ?? "user-settings",
    path: indexLQ7z3ErTiiMeta?.path ?? "/user-settings",
    meta: indexLQ7z3ErTiiMeta || {},
    alias: indexLQ7z3ErTiiMeta?.alias || [],
    redirect: indexLQ7z3ErTiiMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/user-settings/index.vue").then(m => m.default || m)
  },
  {
    name: index8v9ec8gGcaMeta?.name ?? "version",
    path: index8v9ec8gGcaMeta?.path ?? "/version",
    meta: index8v9ec8gGcaMeta || {},
    alias: index8v9ec8gGcaMeta?.alias || [],
    redirect: index8v9ec8gGcaMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/version/index.vue").then(m => m.default || m)
  },
  {
    name: indexhRZXvPcETFMeta?.name ?? "welcome",
    path: indexhRZXvPcETFMeta?.path ?? "/welcome",
    meta: indexhRZXvPcETFMeta || {},
    alias: indexhRZXvPcETFMeta?.alias || [],
    redirect: indexhRZXvPcETFMeta?.redirect,
    component: () => import("/opt/apps/transkribus_app/pages/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubKzQrTTlmreMeta?.name ?? undefined,
    path: component_45stubKzQrTTlmreMeta?.path ?? "/home",
    meta: component_45stubKzQrTTlmreMeta || {},
    alias: component_45stubKzQrTTlmreMeta?.alias || [],
    redirect: component_45stubKzQrTTlmreMeta?.redirect,
    component: component_45stubKzQrTTlmre
  }
]