import validate from "/opt/apps/transkribus_app/node_modules/nuxt/dist/pages/runtime/validate.js";
import preserve_45query_45param_45global from "/opt/apps/transkribus_app/middleware/preserveQueryParam.global.ts";
import strip_45locale_45global from "/opt/apps/transkribus_app/middleware/stripLocale.global.ts";
import manifest_45route_45rule from "/opt/apps/transkribus_app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  preserve_45query_45param_45global,
  strip_45locale_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/apps/transkribus_app/middleware/auth.ts")
}